<template>
  <div id="homepage-header">
    <div class="content container" style="text-align: left; font-size: 20px;">
      <div v-html="banner_title" />
      <!-- <div class="button-banner"> -->
      <a :href="banner_button_link" target="_blank" class="no-underline">
        <strong>{{ banner_button_text }}</strong>
      </a>
      <!-- <RouterLink v-if="banner_button_link" :to="banner_button_link" class="no-underline">
          <strong>{{ banner_button_text }}</strong>
        </RouterLink> -->
      <!-- </div> -->
    </div>

    <span id="scroll-down" @click="scrollToBottom">
      <img :src="icon_arrow_white" alt="">
    </span>

    <div class="video-wrapper">
      <!-- <video preload="auto" autoplay muted loop :src="videoUrl"></video> -->
      <img :src="banner_image" alt="banner image">
      <div class="blur-overlay"></div>

    </div>
  </div>
</template>


<script>
export default {
  props: {
    banner_image: String,
    banner_title: String,
    banner_button_text: String,
    banner_button_link: String,
  },
  data() {
    return {
      videoUrl: require('@/assets/wind_turbine_video.mp4'),
      icon_arrow_white: require('@/assets/icons/icon_arrow-white.png'),
      icon_arrow_white1x: require('@/assets/icons/icon_arrow-white.png'),
      icon_arrow_white2x: require('@/assets/icons/icon_arrow-white@2x.png'),

    };
  },

  methods: {
    getField(field) {
      return field;
    },
    getTemplateDirectoryUri() {
      return 'Simulated template directory URI';
    },
    scrollToBottom() {
      const homepageHeader = document.getElementById('homepage-header');
      const height = homepageHeader.clientHeight;

      window.scrollTo({
        top: height,
        behavior: 'smooth',
      });
    },
  },
};
</script>


<style>
@import '@/css/main.css';

.no-underline {
  text-decoration: none;

}

.button-banner {
  background-color: initial;
  /* Set the initial background color */
  color: initial;
  /* Set the initial text color */
  transition: background-color 0.3s, color 0.3s;
  /* Add a smooth transition effect */
}

.button-banner:hover {
  background-color: grey;
  /* Set the background color on hover */
  color: black;
  /* Set the text color on hover */
}

/* .blur-overlay {
  position: absolute;
  top: 0px;
  left: 0px; 
  width: 100%; 
  height: 95px; 
  backdrop-filter: blur(3px); 
} */

.blur-overlay {
  position: absolute;
  top: 0px;
  /* Adjust as needed */
  left: 0px;
  /* Adjust as needed */
  width: 100%;
  /* Adjust as needed */
  height: 100%;
  /* Adjust as needed */
  backdrop-filter: blur(3px);
  /* Adjust the blur intensity */
}</style>

<template>
  <footer :style="{backgroundColor:'#f5f5f5'}">
    <div class="main-footer">
      <div class="container">
        <div class="row">
          <div v-for="menuItem in currentLanguage === 'ro' ? footerData.items_ro : footerData.items_en" :key="menuItem.id"
            class="col-md-3 col-sm-6 col-xs-12">
            <h5 :style="{color:'black'}">{{ menuItem.section_title }}</h5>
            <div class="textwidget">
              <ul>
                <li>
                  <a :href="menuItem.link" class="no-underline" :style="{color:'black'}">{{ menuItem.item_title }}</a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="copyright-footer" :style="{backgroundColor:'#e5e5e5 '}">
      <div v-if="currentLanguage === 'ro'" class="container text-center" :style="{color:'black'}">
        ©Copyright Ingka Investments Romania {{ currentYear }}. Toate drepturile de autor sunt rezervate.
      </div>
      <div v-else class="container text-center" :style="{color:'black'}">
        ©Copyright Ingka Investments Romania {{ currentYear }}. All author rights are reserved.
      </div>
    </div>
  </footer>
</template>

<script>
import axios from 'axios';


export default {
  data() {
    return {
      currentYear: new Date().getFullYear(),

      footerData: {},
      section_title_ro: '',
      section_title_en: '',
      item_title_ro: '',
      item_title_en: '',
      link_ro: '',
      link_en: '',

    };
  },
  computed: {
    currentLanguage() {
      return this.$store.getters.getCurrentLanguage;
    },
  },
  created() {
    const savedLanguage = localStorage.getItem("currentLanguage");
    if (savedLanguage) {
      this.$store.dispatch("toggleLanguage");
    }
  },
  mounted() {
    const token = 'b246eda339b1157fda4c9795dbc4150851e94fbb';

    axios.get('https://app-landing-page-ro-backend-dev.azurewebsites.net/api/footer-content', {
      headers: {
        'Authorization': `Token ${token}`,
      },
    })
      .then(response => {
        this.footerData = response.data || { items_ro: [], items_en: [] };

        if (this.footerData.items_ro[0].active === true) {
          this.section_title_ro = this.footerData.items_ro[0].section_title;
          this.item_title_ro = this.footerData.items_ro[0].item_title;
          this.link_ro = this.footerData.items_ro[0].link;
        }
        if (this.footerData.items_en[0].active === true) {
          this.section_title_en = this.footerData.items_en[0].section_title;
          this.item_title_en = this.footerData.items_en[0].item_title;
          this.link_en = this.footerData.items_en[0].link;
        }

      })
      .catch(error => {
        console.error('Error fetching Footer data:', error);
      });


    const savedLanguage = localStorage.getItem("currentLanguage");
    if (savedLanguage) {
      this.$store.dispatch("toggleLanguage");
    }
  }
}

</script>
    
<style >
@import '@/css/main.css';

.no-underline {
  text-decoration: none;
}

  html, body {
    height: 100%;
    margin: 0;
    padding: 0;
  }

  #app {
    min-height: 100%;
    display: flex;
    flex-direction: column;
  }

  footer {
    margin-top: auto;
  }
</style>
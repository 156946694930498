<template>
    <section class="page-section section-cta">
        <div class="container">
            <div class="cards-wrapper cards-col-1">
                <div class="card-element">
                    <div class="card-wrapper">
                        <div class="card-content">
                            <h4>
                                {{ section_title }}
                            </h4>
                            <div v-html="gdpr_policy"></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
export default {
    props: {
        section_title: String,
        gdpr_policy: String
    },
    
}
</script>

<style>
@import '@/css/main.css';
</style>
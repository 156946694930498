<template>
    <section class="page-section section-photo-cards">
        <div class="container">
            <div class="content">
                <div v-html="section_content" />
            </div>

            <div class="cards-wrapper">
                <div class="card-element blue">
                    <a :href="button_link" :title="button_text" class="card-wrapper">
                        <div class="card-image" :style="{height: '18rem'}">
                            <img :src=section_image alt="photo" />                             
                        </div>
                        <div class="card-content"  :style="{backgroundColor:'#e5e5e5 '}">
                            <h2>
                                <div v-html="section_title"  :style="{color:'black'}"/>
                            </h2>
                            <div v-html="button_text" class="no-underline" :style="{color:'black', textDecoration: 'none'}"/>
                        </div>
                    </a>
                </div>
            </div>
        </div>
    </section>
</template>

<script>

export default {
    props: {
        section_image: String,
        section_title: String,
        section_content: String,
        button_text: String,
        button_link: String,
    }
};
</script>

<style>
@import '@/css/main.css';
.no-underline {
  text-decoration: none;
}
</style>


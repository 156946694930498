<template>
  <div :style="{ marginTop: firstPage ? '0' : '60px' }">
    <header :style="headerStyle">
      <div class="container clearfix">
        <RouterLink v-if="!scrolled && firstPage" to="/" id="logo">
          <img :src="whiteLogoSrc" alt="White Logo" />
          <!-- <img :src="logoUrl" alt="White Logo" /> -->

        </RouterLink>

        <RouterLink v-else to="/" id="logo">
          <img :src="blueLogoSrc" alt="Blue Logo" />
        </RouterLink>

        <div v-if="!scrolled && firstPage" id="menu-toggle" @click="toggleMenu">
          <span style="background-color: white;"></span>
          <span style="background-color: white;"></span>
          <span style="background-color: white;"></span>
          <span style="background-color: white;"></span>
        </div>

        <div v-else id="menu-toggle" @click="toggleMenu">
          <span></span>
          <span></span>
          <span></span>
          <span></span>
        </div>

        <nav class="main-navigation" :class="{ 'show-menu': isMenuVisible }">
          <div class="menu-main-navigation-ro-container">
            <ul id="menu-main-navigation-ro" class="menu">
              <li v-for="menuItem in currentLanguage === 'ro' ? headerData.menu_items_ro : headerData.menu_items_en"
                :key="menuItem.id">
                <a :href="menuItem.link" target="_blank" class="no-underline"
                  :style="{ color: firstPage && !scrolled ? 'white' : '' }">
                  {{ menuItem.title }}
                </a>
              </li>

              <!-- language button -->
              <li class="language-menu">
                <p v-if="firstPage && !scrolled" class="language-button-sub-white">
                  {{ currentLanguage }}
                </p>
                <p v-else class="language-button-sub">
                  {{ currentLanguage }}
                </p>
                <div :style="{ height: 'auto' }" class="language-dropdown-content">
              <li v-if="currentLanguage === 'ro'" @click="toggleLanguage">EN</li>
              <li v-if="currentLanguage === 'en'" @click="toggleLanguage">RO</li>
          </div>
          </li>
          </ul>
      </div>
      </nav>
  </div>

  </header>
  <div v-if="isMenuVisible" class="white-menu">
    <img v-if="!scrolled" :src="blueLogoSrc" alt="Blue Logo" class="center-logo" />
    <div v-if="isMenuVisible" class="close-button" @click="toggleMenu">&times;</div>

    <ul id="menu-main-navigation-ro" class="menu center-menu text-left">
      <li v-for="menuItem in currentLanguage === 'ro' ? headerData.menu_items_ro : headerData.menu_items_en"
        :key="menuItem.id">
        <!-- <RouterLink :to="menuItem.link" class="no-underline" :class="{ 'white-text': !scrolled && firstPage }">
          {{ menuItem.title }}
        </RouterLink> -->
        <a :href="menuItem.link" target="_blank" class="no-underline">
          {{ menuItem.title }}
        </a>
      </li>
      <li class="language-menu">
        <p class="language-button-sub">
          {{ currentLanguage }}
        </p>
        <div :style="{ height: 'auto' }" class="language-dropdown-content">
      <li v-if="currentLanguage === 'ro'" @click="toggleLanguage">EN</li>
      <li v-if="currentLanguage === 'en'" @click="toggleLanguage">RO</li>
  </div>
  </li>
  </ul>
  </div>
  </div>
</template>

<script>
import axios from 'axios';

export default {
  props: {
    firstPage: Boolean
  },
  data() {
    return {
      headerData: {},
      blueLogoSrc: require('@/assets/Ingka_investments_wordmark_Blue_PMS.png'), // Move the image source to the component's data
      whiteLogoSrc: require('@/assets/Ingka_investments_wordmark_White_CMYK.png'),
      scrolling: false,
      lastScroll: 0,
      scrolled: false,
      isMenuVisible: false,
      photoUrl: "https://app-landing-page-ro-backend-dev.azurewebsites.net",
      logoUrl: '',

    };
  },
  computed: {
    currentLanguage() {
      return this.$store.getters.getCurrentLanguage;
    },
    headerStyle() {
      return {
        top: this.scrolling ? '-100px' : '0',
        backgroundColor: this.isMenuVisible ? 'white' : (this.scrolled ? 'white' : 'transparent'),
        borderBottom: this.firstPage && !this.scrolling ? 'none' : '1px solid #E1E5E8'
      };
    },
  },
  methods: {

    toggleLanguage() {
      this.$store.dispatch('toggleLanguage');
    },
    handleScroll() {
      const currentScroll = window.pageYOffset;
      this.scrolling = currentScroll > this.lastScroll && currentScroll > 50;
      this.lastScroll = currentScroll <= 0 ? 0 : currentScroll;
      this.scrolled = window.scrollY > 50;
    },
    toggleMenu() {
      this.isMenuVisible = !this.isMenuVisible;
    },
  },
  created() {
    const savedLanguage = localStorage.getItem("currentLanguage");
    if (savedLanguage) {
      this.$store.dispatch("toggleLanguage");
    }
  },
  mounted() {
    window.addEventListener('scroll', this.handleScroll);

    const token = 'b246eda339b1157fda4c9795dbc4150851e94fbb';

    axios.get('https://app-landing-page-ro-backend-dev.azurewebsites.net/api/menu-content', {
      headers: {
        'Authorization': `Token ${token}`,
      }
    })
      .then(response => {
        this.headerData = response.data || { items_ro: [], items_en: [] };
        this.logoUrl = "https://app-landing-page-ro-backend-dev.azurewebsites.net" + this.headerData.logo[0].logo;
      })
      .catch(error => {
        console.log('Error fetching Header data:', error);
      });
    console.log(this.logoUrl)
    const savedLanguage = localStorage.getItem("currentLanguage");
    if (savedLanguage) {
      this.$store.dispatch("toggleLanguage");
    }

  },
  beforeUnmount() {
    window.removeEventListener('scroll', this.handleScroll); // Remove listener on component destroy
  },
};

</script>

<style>
@import '@/css/main.css';

.no-underline {
  text-decoration: none;
}

.white-text {
  color: white;
}

.white-menu-toggle {
  background-color: white;
}

.white-menu {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: white;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  z-index: 1000;
}

.center-logo {
  position: absolute;
  top: 0;
  margin-left: 2rem;
  height: 60px;
  margin: 0;
}

.center-menu {
  margin-left: 2rem;
  list-style: none;
  padding: 0;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}

.center-menu li {
  margin-bottom: 10px;
  text-align: center;
}

.center-menu li a {
  color: #6E8496;
  text-align: center;
}

.center-menu li a :hover {
  color: #0058A3;
}

.show-menu {
  display: block;
  position: absolute;
  top: 60px;
  right: 0;
  background-color: white;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  z-index: 1001;
}

.close-button {
  position: absolute;
  top: 0;
  right: 2rem;
  cursor: pointer;
  font-size: 42px;
  color: #0058A3;
  background-color: transparent;
  z-index: 1002;
}

.language-menu {
  list-style: none;
  position: relative;
  display: inline-flex;
  z-index: 2000;
}

.language-button-sub {
  cursor: pointer;
  margin-top: 25%;
  position: relative;
  color: #6E8496;
  text-align: right;
}

.language-button-sub-white {
  cursor: pointer;
  margin-top: 25%;
  position: relative;
  color: white;
  text-align: right;
}

.language-button-sub::after {
  content: '\02C5';
  display: inline-flex;
  margin-left: 3px;
  font-size: 70%;
}

.language-button-sub:hover::after {
  content: '\02C4';
  display: inline-flex;
  font-size: 70%;
  margin-left: 3px;
}

.language-button-sub-white::after {
  content: '\02C5';
  display: inline-flex;
  margin-left: 3px;
  font-size: 70%;
}

.language-button-sub-white:hover::after {
  content: '\02C4';
  display: inline-flex;
  font-size: 70%;
  margin-left: 3px;
}

.language-dropdown-content {
  display: none;
  position: absolute;
}

.language-dropdown-content li {
  background-color: #D3D3D3;
  color: #6E8496;
  font-weight: 600;

}

.language-menu:hover .language-dropdown-content {
  top: 80%;
  display: inline-flex;
  background-color: #fff;
  left: 0;
}

li p {
  text-transform: uppercase;
  color: #6E8496;
}

ul {
  list-style-type: none;
  left: 0;
}

.menu li a {
  text-decoration: none;
  /* Remove default underline */
  position: relative;
  /* transition: text-decoration 2s; */
  /* Add transition for 2 seconds */
}

.menu li a:hover {
  text-decoration: underline;
  /* content: ""; */
  opacity: 1;
  /* background: #111; */
  /* width: calc(100% - 10px); */
  /* height: 1px; */
  transition: opacity .22s linear;
  /* position: absolute; */
  /* bottom: 30%; */
  /* left: 50%; */
  /* transform: translate(-50%); */
}

/* Additional CSS /
.no-underline {
text-decoration: none !important; / Ensure no underline for elements with the class 'no-underline' */
/* } */</style>

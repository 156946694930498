<template >
    <NavbarMain :first-page="true" />

    <BannerFirstPage v-if="currentLanguage === 'ro'" :banner_image="mainImage" :banner_title="banner_title_ro"
        :banner_button_text="banner_button_text_ro" :banner_button_link="banner_button_link_ro" />
    <BannerFirstPage v-else :banner_image="mainImage" :banner_title="banner_title_en"
        :banner_button_text="banner_button_text_en" :banner_button_link="banner_button_link_en" />

    <ContentPage v-if="currentLanguage === 'ro'" :first_section_image="energyImage"
        :first_section_title="first_section_title_ro" :first_section_content="first_section_content_ro" />
    <ContentPage v-else :first_section_image="energyImage" :first_section_title="first_section_title_en"
        :first_section_content="first_section_content_en" />

    <div class="columns">
        <PhotoCards v-if="currentLanguage === 'ro'" :section_image="turbineImage"
            :section_title="second_section_title_ro" :section_content="second_section_content_ro"
            :button_text="second_section_button_text_ro" :button_link="second_section_button_link_ro" />
        <PhotoCards v-else :section_image="turbineImage" :section_title="second_section_title_en"
            :section_content="second_section_content_en" :button_text="second_section_button_text_en"
            :button_link="second_section_button_link_en" />


        <PhotoCards v-if="currentLanguage === 'ro'" :section_image="forestImage"
            :section_title="third_section_title_ro" :section_content="third_section_content_ro"
            :button_text="third_section_button_text_ro" :button_link="third_section_button_link_ro" />
        <PhotoCards v-else :section_image="forestImage" :section_title="third_section_title_en"
            :section_content="third_section_content_en" :button_text="third_section_button_text_en"
            :button_link="third_section_button_link_en" />
    </div>

    <CookieWarningView/>

    <FooterPage />
</template>
  
<script>
import axios from 'axios';

import NavbarMain from '../components/NavbarMain.vue';
import FooterPage from '../components/FooterPage.vue';
import BannerFirstPage from '../components/BannerFirstPage.vue';
import PhotoCards from '../components/PhotoCards.vue';
import ContentPage from '@/components/ContentPage.vue';
import CookieWarningView from './CookieWarningView.vue';

export default {
    components: {
    NavbarMain,
    FooterPage,
    BannerFirstPage,
    PhotoCards,
    ContentPage,
    CookieWarningView
},
    data() {
        return {
            energyImage: require('@/assets/ewind-green-energy.jpg'),
            mainImage: require('@/assets/ingka_rock_landingpage.jpg'),
            forestImage: require('@/assets/ingka_leaves_landingpage.jpg'),
            turbineImage: require('@/assets/homepage.jpg'),

            homepageData: {
                items_ro: [{
                    page_name: '',
                    banner_image: '',
                    banner_title: '',
                    banner_button_text: '',
                    banner_button_link: '',
                    first_section_image: '',
                    first_section_title: '',
                    first_section_content: '',
                    second_section_image: '',
                    second_section_title: '',
                    second_section_content: '',
                    second_section_button_text: '',
                    second_section_button_link: '',
                    third_section_image: '',
                    third_section_title: '',
                    third_section_content: '',
                    third_section_button_text: '',
                    third_section_button_link: '',
                    active: '',
                }],
                items_en: [{
                    page_name: '',
                    banner_image: '',
                    banner_title: '',
                    banner_button_text: '',
                    banner_button_link: '',
                    first_section_image: '',
                    first_section_title: '',
                    first_section_content: '',
                    second_section_image: '',
                    second_section_title: '',
                    second_section_content: '',
                    second_section_button_text: '',
                    second_section_button_link: '',
                    third_section_image: '',
                    third_section_title: '',
                    third_section_content: '',
                    third_section_button_text: '',
                    third_section_button_link: '',
                    active: '',
                }]
            },
            page_name_ro: '',
            page_name_en: '',
            banner_image_ro: '',
            banner_image_en: '',
            banner_title_ro: '',
            banner_title_en: '',
            banner_button_text_ro: '',
            banner_button_text_en: '',
            banner_button_link_ro: '',
            banner_button_link_en: '',
            first_section_image_ro: '',
            first_section_image_en: '',
            first_section_title_ro: '',
            first_section_title_en: '',
            first_section_content_ro: '',
            first_section_content_en: '',
            second_section_image_ro: '',
            second_section_image_en: '',
            second_section_title_ro: '',
            second_section_title_en: '',
            second_section_content_ro: '',
            second_section_content_en: '',
            second_section_button_text_ro: '',
            second_section_button_text_en: '',
            second_section_button_link_ro: '',
            second_section_button_link_en: '',
            third_section_image_ro: '',
            third_section_image_en: '',
            third_section_title_ro: '',
            third_section_title_en: '',
            third_section_content_ro: '',
            third_section_content_en: '',
            third_section_button_text_ro: '',
            third_section_button_text_en: '',
            third_section_button_link_ro: '',
            third_section_button_link_en: '',
            active_ro: '',
            active_en: '',

        };
    },
    computed: {
        currentLanguage() {
            return this.$store.getters.getCurrentLanguage;
        },
    },
    created() {
        const savedLanguage = localStorage.getItem("currentLanguage");
        if (savedLanguage) {
            this.$store.dispatch("toggleLanguage");
        }
    },
    
    mounted() {
        const token = 'b246eda339b1157fda4c9795dbc4150851e94fbb';

        axios.get('https://app-landing-page-ro-backend-dev.azurewebsites.net/api/home-content', {
            headers: {
                'Authorization': `Token ${token}`,
            },
        })
            .then(response => {
                this.homepageData = response.data || { items_ro: [], items_en: [] };

                if (this.homepageData.items_ro[0].active === true) {
                    this.banner_image_ro = "https://app-landing-page-ro-backend-dev.azurewebsites.net" + this.homepageData.items_ro[0].banner_image;
                    this.banner_title_ro = this.homepageData.items_ro[0].banner_title;
                    this.banner_button_text_ro = this.homepageData.items_ro[0].banner_button_text;
                    this.banner_button_link_ro = this.homepageData.items_ro[0].banner_button_link;
                    this.first_section_image_ro = "https://app-landing-page-ro-backend-dev.azurewebsites.net" + this.homepageData.items_ro[0].first_section_image;
                    this.first_section_title_ro = this.homepageData.items_ro[0].first_section_title;
                    this.first_section_content_ro = this.homepageData.items_ro[0].first_section_content;
                    this.second_section_image_ro = "https://app-landing-page-ro-backend-dev.azurewebsites.net" + this.homepageData.items_ro[0].second_section_image;
                    this.second_section_title_ro = this.homepageData.items_ro[0].second_section_title;
                    this.second_section_content_ro = this.homepageData.items_ro[0].second_section_content;
                    this.second_section_button_text_ro = this.homepageData.items_ro[0].second_section_button_text;
                    this.second_section_button_link_ro = this.homepageData.items_ro[0].second_section_button_link;
                    this.third_section_image_ro = "https://app-landing-page-ro-backend-dev.azurewebsites.net" + this.homepageData.items_ro[0].third_section_image;
                    this.third_section_title_ro = this.homepageData.items_ro[0].third_section_title;
                    this.third_section_content_ro = this.homepageData.items_ro[0].third_section_content;
                    this.third_section_button_text_ro = this.homepageData.items_ro[0].third_section_button_text;
                    this.third_section_button_link_ro = this.homepageData.items_ro[0].third_section_button_link;
                }

                if (this.homepageData.items_en[0].active === true) {
                    this.banner_image_en = "https://app-landing-page-ro-backend-dev.azurewebsites.net" + this.homepageData.items_en[0].banner_image;
                    this.banner_title_en = this.homepageData.items_en[0].banner_title;
                    this.banner_button_text_en = this.homepageData.items_en[0].banner_button_text;
                    this.banner_button_link_en = this.homepageData.items_en[0].banner_button_link;
                    this.first_section_image_en = "https://app-landing-page-ro-backend-dev.azurewebsites.net" + this.homepageData.items_en[0].first_section_image;
                    this.first_section_title_en = this.homepageData.items_en[0].first_section_title;
                    this.first_section_content_en = this.homepageData.items_en[0].first_section_content;
                    this.second_section_image_en = "https://app-landing-page-ro-backend-dev.azurewebsites.net" + this.homepageData.items_en[0].second_section_image;
                    this.second_section_title_en = this.homepageData.items_en[0].second_section_title;
                    this.second_section_content_en = this.homepageData.items_en[0].second_section_content;
                    this.second_section_button_text_en = this.homepageData.items_en[0].second_section_button_text;
                    this.second_section_button_link_en = this.homepageData.items_en[0].second_section_button_link;
                    this.third_section_image_en = "https://app-landing-page-ro-backend-dev.azurewebsites.net" + this.homepageData.items_en[0].third_section_image;
                    this.third_section_title_en = this.homepageData.items_en[0].third_section_title;
                    this.third_section_content_en = this.homepageData.items_en[0].third_section_content;
                    this.third_section_button_text_en = this.homepageData.items_en[0].third_section_button_text;
                    this.third_section_button_link_en = this.homepageData.items_en[0].third_section_button_link;
                }
                // console.log(this.first_section_image_ro)
            })
            .catch(error => {
                console.log('Error fetchig Homepage data:', error)
            });

        const savedLanguage = localStorage.getItem("currentLanguage");
        if (savedLanguage) {
            this.$store.dispatch("toggleLanguage");
        }
    }
};
</script>
  
<style>
#app {
    font-family: Avenir, Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-align: center;
    color: #2c3e50;
}

.columns {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 10px;
}

@media all and (max-width: 850px) {
    .columns {
        display: grid;
        grid-template-columns: 1fr;
        gap: 10px;
    }
}
</style>
  
import { createStore } from "vuex";

const savedLanguage = localStorage.getItem("currentLanguage");

export default createStore({
    state: {
        currentLanguage: savedLanguage || "ro",
    },
    mutations: {
        toggleLanguage(state) {
            state.currentLanguage = state.currentLanguage === 'ro' ? 'en' : 'ro';
            localStorage.setItem("currentLanguage", state.currentLanguage);

        },
    },
    actions: {
        toggleLanguage({ commit }) {
            commit('toggleLanguage');
        },
    },
    getters: {
        getCurrentLanguage: (state) => state.currentLanguage,
    },
})
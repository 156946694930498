<template>
  <section class="page-section section-content-with-photo">
    <div class="container-fluid">
      <div class="block-wrapper">
        <div class="image-wrapper">
          <img :src="first_section_image" alt="homepage">
        </div>

        <div class="content-wrapper">
          <h3>
            <div v-html="first_section_title" />
          </h3>
          <div v-html="first_section_content" />
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {

  props: {
    first_section_image: String,
    first_section_title: String,
    first_section_content: String,
  },
}
</script>

<style>
@import '@/css/main.css';
</style>
<template>
    <NavbarMain :first-page="false" />
    <GDPRContent v-if="currentLanguage === 'ro'" :section_title="section_title_ro" :gdpr_policy="gdpr_policy_ro" />
    <GDPRContent v-else :section_title="section_title_en" :gdpr_policy="gdpr_policy_en" />
    <FooterPage />
</template>

<script>
import axios from 'axios';

import NavbarMain from '../components/NavbarMain.vue'
import FooterPage from '../components/FooterPage.vue'
import GDPRContent from '@/components/GDPRContent.vue';

export default {
    components: {
        NavbarMain,
        FooterPage,
        GDPRContent
    },
    data() {

        return {
            gdprData: {
                items_ro: [{ section_title: '', gdpr_policy: '' }],
                items_en: [{ section_title: '', gdpr_policy: '' }],
            },
            section_title_ro: '',
            section_title_en: '',
            gdpr_policy_ro: '',
            gdpr_policy_en: '',
        }
    },
    computed: {
        currentLanguage() {
            return this.$store.getters.getCurrentLanguage;
        },
    },
    created() {
        const savedLanguage = localStorage.getItem("currentLanguage");
        if (savedLanguage) {
            this.$store.dispatch("toggleLanguage");
        }
    },
    mounted() {
        const token = 'b246eda339b1157fda4c9795dbc4150851e94fbb';

        axios.get('https://app-landing-page-ro-backend-dev.azurewebsites.net/api/gdpr-content', {
            headers: {
                'Authorization': `Token ${token}`,
            },
        })
            .then(response => {
                this.gdprData = response.data || { items_ro: [], items_en: [] };

                if (this.gdprData.items_ro[0].active === true) {
                    this.section_title_ro = this.gdprData.items_ro[0].section_title;
                    this.gdpr_policy_ro = this.gdprData.items_ro[0].gdpr_policy;
                }
                if (this.gdprData.items_en[0].active === true) {
                    this.section_title_en = this.gdprData.items_en[0].section_title;
                    this.gdpr_policy_en = this.gdprData.items_en[0].gdpr_policy;
                }
            })
            .catch(error => {
                console.error('Error fetching GDPR data:', error);
            });


        const savedLanguage = localStorage.getItem("currentLanguage");
        if (savedLanguage) {
            this.$store.dispatch("toggleLanguage");
        }
    }
}
</script>

<style></style>
import { createRouter, createWebHistory } from 'vue-router'

import HomepageView from '@/views/HomepageView.vue'
import GDPRView from '@/views/GDPRView.vue'
import ErrorView from '@/views/ErrorView'

const routes = [
    {
        path: '/',
        name: 'home',
        component: HomepageView
    },
    {
        path: '/gdpr',
        name: 'gdpr',
        component: GDPRView
    },
    { 
        path: '/:catchAll(.*)',
        component: ErrorView
    },

]

const router = createRouter({
    history: createWebHistory(),
    routes,
})

export default router;